import logo from './logo.svg';
import './App.css';
import Calendar from './components/Calendar/Calendar';
import { BrowserRouter} from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Calendar/>
      That's me! <strong>IT NUS</strong>
        
    </BrowserRouter>

  );
}

export default App;
