import { MDBContainer,MDBBreadcrumb,MDBBreadcrumbItem, MDBSelect, MDBCol } from "mdbreact";
import React, { Component } from "react";
import { Calendar as MyCalendar, momentLocalizer  }  from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/es';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import {list} from "./functions";

const localizer = momentLocalizer(moment)

const users = [
  { value: '', text: 'Todos' },
  { value: '4379473000015456001', text: 'Ana Miranda' },
  { value: "4379473000037963001", text: 'Carlos Rodríguez' },
  { value: '4379473000031957001', text: 'Valeria Flores' },
];

class Calendar extends Component {


  handleSelected = (event) => {
    window.parent.location.href = "https://crm.zoho.com/crm/org706779335/tab/Activities/"+event.idcrm+"?sub_module=Tasks";
  };

  handleChangeUser = (event) => {
    if (event[0] === "") {
      this.setState({
        events: this.state.results,
        user: { value: '', text: 'Todos' }
      })

    } else {
      this.setState({
        events: this.state.results.filter(element => element["Owner"].id === event[0]),
        user: users.filter(element => element["value"] === event[0])[0]
      })
    }
  }
  componentDidMount() {
    let myPromise = new Promise((resolve, reject) => {
      const pp = list();
      resolve(pp);
    });
    var uuu = [];
    var i=0;
    myPromise.then( (results) => {

      results.data.forEach(element => {
        i++;
        var name = "";
        if (element["Who_Id"] === null) {
          name = ""
        } else {
          name = element["Who_Id"]["name"]
        }
        if (element["What_Id"] != null) {
          name += "("+element["What_Id"]["name"]+")"
        }

        if (element["Status"] !== "Completado") {
          uuu.push(
            {
              id: i,
              idcrm: element["id"],
              Owner: element["Owner"],
              Priority: element["Priority"],
              Tipo: element["Tipo"],
              title: name + " - " + element["Subject"],
              start: (element["Hora"] === null) ? moment(element["Due_Date"], "YYYY-MM-DD").toDate() : moment(element["Hora"]).toDate() ,
              allDay: false,
              end: (element["Hora"] === null) ? moment(element["Due_Date"], "YYYY-MM-DD").toDate() : moment(element["Hora"]).toDate() ,
              eventPropGetter: {
                style: { backgroundColor: "green" }

              }
            }
          );
        }
      });  
      this.setState({
        events:uuu,
        results: uuu
      })
    });
    myPromise.catch( (err) => {
      console.log("EEEEE: ",err)
    });

  }
  state = {
    events: [],
    results: [],
    user: { value: '', text: 'Todos' },
  }
  
  render() {

    return (
      <MDBContainer>
        <MDBCol size="4">
        <MDBSelect
          options={users}
          getValue={this.handleChangeUser}
          selected={this.state.user["text"]}>
        </MDBSelect>
        </MDBCol>
        <MyCalendar localizer={localizer}
          events={this.state.events}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={this.handleSelected}
          messages={
            {
              next:"Siguiente",
              previous:"Anterior",
              today:"Hoy",
              month: "Mes",
              week: "Semana",
              day: "Día",
              Agenda: "Agenda"
            }
          }
          eventPropGetter= {(event, start, end, isSelected) => (
            
            {
              event,
              start,
              end,
              isSelected,
              style: (event.Tipo==="Volver a llamar")  ? { backgroundColor: `rgb(255,0,0)` } : 
                (event.Tipo==="Pago")  ? { backgroundColor: `rgb(0,255,0)` } : 
                (event.Tipo==="Entrevista")  ? { backgroundColor: `#C8A2C8` } :
                (event.Tipo==="Documentos")  ? { backgroundColor: `rgb(0,0,255)` } :
                (event.Tipo==="RPA")  ? { backgroundColor: `#ffff00` } : { backgroundColor: `#3174ad`}
            }
            
          )}
          style={{ height: "90vh" }}
        />
      </MDBContainer>
    );
  }
}

export default Calendar;