
// Producció
module.exports = {
    ZOHO_CLIENT_ID : "1000.B8V889K00YP7Y7N8JJ2TXFPLRJS80Q",
    ZOHO_CLIENT_SECRET : "3f4ff6085b95e9fb516630559ca82a55ab11816056",
    ZOHO_REDIRECT_URI : "https://zoho.nus.agency",
    ZOHO_URI_CRM : "https://www.zohoapis.com/crm/v2/",
    ZOHO_URI_ACCOUNT : "https://accounts.zoho.eu/",
    ZOHO_CODE: "1000.75e81ee1235ac3dd54567bd9ac186699.277f385e9ecf3021de24442da252c44d",
    ZOHO_ACCESS_TOKEN :  "1000.be109137c18a635372fc73772d3dbf08.347a16634014cee9bb273264901b2a12",
    ZOHO_REFRESH_TOKEN : "1000.b4fe09e42f836392da8106a6c7f7b0e2.de017d257401412953e0d40c9abbc134",
};